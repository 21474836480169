import { useState, useMemo } from 'react';

function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
}

export default function useWindowDimensions() {
  if (typeof window !== `undefined`) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useMemo(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  } else {
    return {
      width: 0,
      height: 0,
    };
  }
}
