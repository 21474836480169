const Utils = () => ({
  formatLocation: (location) => {
    const locationParts = location.replace(/\s+/g, '').split(',');
    return {
      city: locationParts[0],
      state: locationParts[1],
      country: locationParts[2],
    };
  },
});

export default Utils;
