import React, { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from 'material-ui/MenuItem';
import _ from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const AutoComplete = (props) => {
  let menuItems = [];
  let loading = props.items.length !== 0 && menuItems.length === 0;

  useEffect(() => {
    props.items.map((item, index) =>
      menuItems.push(<MenuItem value={item.name} key={index} primaryText={item.name} />),
    );
  }, [props.items]);

  return (
    <div className={props.className}>
      <Autocomplete
        id={props.id}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={props.items}
        autoHighlight={true}
        ChipProps={props.icon ? { icon: props.icon } : null}
        style={{
          width: props.width,
          backgroundColor: '#ffffff',
          border: 'solid 1px #c8c8c8',
        }}
        onChange={(event, value) => props.handleSelect(value != null ? value.name : null)}
        onInputChange={_.debounce((event, value) => props.handleChange(value), 200)}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
            }}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          const parts = parse(option.name, matches);
          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </div>
  );
};
export default AutoComplete;
