import companyQL from './appSyncClient';
import listingQuery from './graphql/listingQuery';
import Utils from './utils';

const ListingSvc = () => {
  return {
    getListingBySlug: async (slug) => {
      try {
        const res = await companyQL.query({ query: listingQuery.getListing(slug) });
        return res.data.searchListings[0];
      } catch (err) {
        return err;
      }
    },
    getPaginationAmount: async (searchTerms) => {
      const { name, category, location, max, offset } = searchTerms;
      const locationParts = location ? Utils().formatLocation(location) : null;
      const { city, state, country } = locationParts || {};

      const res = await companyQL
        .query({
          query: listingQuery.searchListings(
            name || '',
            category || '',
            city || '',
            state || '',
            country || '',
            max || 25,
            offset || 0,
          ),
        })
        .catch(() => ({ data: { searchListings: [] } }));
      return res.data.searchListings.length;
    },
    searchListings: async (searchTerms) => {
      const { name, category, location, max, offset } = searchTerms;
      const locationParts = location ? Utils().formatLocation(location) : null;
      let { city, state, country } = locationParts || {};

      if (country === 'USA') {
        country = 'US';
      }

      const res = await companyQL
        .query({
          query: listingQuery.searchListings(name, category, city, state, country, max || 25, offset || 0),
        })
        .catch(() => ({ data: { searchListings: [] } }));
      return res.data.searchListings;
    },
    getAutocompleteResults: async (searchTerm) => {
      const res = await companyQL
        .query({
          query: listingQuery.getAutocompleteResults(searchTerm),
        })
        .catch(() => ({ data: { getAutocompleteResults: [] } }));
      return res.data.getAutocompleteResults;
    },
    getLocationAutocompleteResults: async (searchTerm) => {
      const res = await companyQL
        .query({
          query: listingQuery.getLocationAutocompleteResults(searchTerm),
        })
        .catch(() => ({ data: { getLocationAutocomplete: [] } }));
      return res.data.getLocationAutocomplete;
    },
    getListingAutocompleteResults: async (searchTerm) => {
      const res = await companyQL
        .query({
          query: listingQuery.getListingAutocompleteResults(searchTerm),
        })
        .catch((err) => {});
      return res.data.searchListings;
    },
  };
};

export default ListingSvc;
