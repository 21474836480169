export const theme = {
  breakpoints: ['32rem', '48rem', '64rem', '75rem'],
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 72, 80, 88, 96],
  fontSize: [12, 14, 16, 20, 24, 32, 36, 48, 64, 10],
  colors: {
    brand: '#24be51',
    text: '#4c6372',
    textLight: '#70838f',
    disabled: '70828E',
    primaryLight: '#48bfff',
    primary: '#2ea5f3',
    primaryDark: '#145f8f',
    gray: '#e2e6e8',
    none: 'none',
  },
  variants: {
    link: {
      anchor: {
        color: 'primary',
        cursor: 'pointer',
        fontSize: [9, 2, 2, 3],
        textDecoration: 'none',
        '&:hover': {
          color: 'primaryDark',
        },
      },
    },
  },
  text: {
    heading1: {
      color: 'text',
      fontSize: [4, 5, 6],
      fontWeight: 300,
    },
    heading2: {
      color: 'text',
      fontSize: [4, 5],
      fontWeight: 300,
    },
    heading3: {
      color: 'text',
      fontSize: [3],
      fontWeight: 'normal',
    },
    specialOffer: {
      color: 'brand',
      fontSize: [9, 2, 2, 3],
      fontWeight: 300,
    },
    searchResultText: {
      color: 'text',
      fontSize: [9, 2, 2, 3],
      fontWeight: 'normal',
    },
    searchResultHeading: {
      color: 'text',
      fontSize: [0, 5],
      fontWeight: 'normal',
    },
    p: {
      color: 'text',
    },
    anchor: {
      color: 'primary',
      cursor: 'pointer',
      fontSize: [3],
      textDecoration: 'none',
      '&:hover': {
        color: 'primaryDark',
      },
    },
  },
  buttons: {
    primary: {
      borderRadius: '200px',
      backgroundColor: 'primary',
      color: 'white',
      paddingY: [3],
      paddingX: [3],
      minWidth: ['185px'],
      fontSize: [3],
      '&:hover': {
        backgroundColor: 'primaryLight',
      },
    },
    icon: {
      backgroundColor: 'transparent',
      borderWidth: 'none',
    },
    outline: {
      borderRadius: '200px',
      backgroundColor: 'transparent',
      color: 'white',
      fontSize: [3],
      border: 'solid 2px white',
      paddingY: [3],
      paddingX: [4],
      '&:hover': {
        color: 'brand',
        backgroundColor: 'white',
      },
    },
  },
};
