import React, { useState, useEffect } from 'react';
import { HamburgerWrapper, StyledLink, StyledLogo, StyledButton } from './StyledHeader';
import listingSvc from '../../../services/listingSvc';
import HeaderSearch from '../../molecules/ListingPage/HeaderSearch/HeaderSearch';
import Hamburger from '../../atoms/Hamburger/Hamburber';
import AutoComplete from '../../atoms/AutoComplete/AutoComplete';
import { useQueryParams, StringParam, NumberParam, ArrayParam, withDefault } from 'use-query-params';
import { navigate, Link } from 'gatsby';
import { Flex, Image, Box } from 'rebass/styled-components';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import MenuIcon from '@material-ui/icons/Menu';

const Header = (props) => {
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [listingsList, setListingsList] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [locationValue, setLocationValue] = useState(null);
  const [listingValue, setListingValue] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const { width } = useWindowDimensions();

  const handleLocationChange = async (value) => {
    const locations = await listingSvc().getLocationAutocompleteResults(value.toLowerCase());
    setLocationList(locations);
  };

  const handleCategoryChange = async (value) => {
    const catagories = await listingSvc().getAutocompleteResults(value.toLowerCase());
    setCategoryList(catagories);
  };

  const handleInputChange = async (value) => {
    let listings;
    if (value !== '') {
      listings = await listingSvc().getListingAutocompleteResults(value.toLowerCase());
    }
    listings !== undefined ? setListingsList(listings) : null;
  };

  const handleLocationSelect = (value) => {
    setLocationValue(value);
  };

  const handleListingSelect = (value) => {
    setListingValue(value);
  };

  const handleCategorySelect = (value) => {
    setCategoryValue(value);
  };

  const expand = () => {
    expanded === true ? setExpanded(false) : setExpanded(true);
  };

  const conditionals = {
    listingFilled: listingValue != null,
    locationFilled: locationValue != null,
    categoryFilled: categoryValue != null,
  };

  const handleSearchSubmit = async () => {
    navigate(
      `/search?${listingValue ? `name=${listingValue}` : ''}${
        listingValue && locationValue ? `&location=${locationValue}` : ''
      }${locationValue && !listingValue ? `location=${locationValue}` : ''}${
        (categoryValue && locationValue) || (categoryValue && listingValue) ? `&category=${categoryValue}` : ''
      }${(categoryValue && !listingValue) || (categoryValue && !locationValue) ? `category=${categoryValue}` : ''} `,
    );
    setExpanded(false);
  };

  const formatLocations = (locations) => {
    return locations.map((location) => ({ name: location }));
  };

  return (
    <>
      <Box
        as="nav"
        width={1}
        className="header"
        bg="white"
        py={[6]}
        sx={{ boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.04)' }}
        px={5}
      >
        <Flex
          flexDirection="row"
          width={1}
          maxWidth={1232}
          mx="auto"
          alignItems="center"
          justifyContent="space-between"
        >
          <a style={{ width: '210px' }} href={props.location.pathname === '/' ? 'https://grandio.company.com/' : '/'}>
            <Image
              sx={{
                maxWidth: 300,
              }}
              className="Landing-Header-Logo"
              alt="Company.com"
              maxHeight={['24px', null, '52px']}
              src="https://assets-dev.company-corp.com/dashboard_nextgen/company/images/logo.svg"
            />
          </a>
          {props.includeSearchBar ? (
            width > 780 ? (
              <HeaderSearch
                categories={categoryList}
                locations={locationList}
                listings={listingsList}
                handleInputChange={handleInputChange}
                handleCategoryChange={handleCategoryChange}
                handleLocationChange={handleLocationChange}
                handleSearchSubmit={handleSearchSubmit}
                handleLocationSelect={handleLocationSelect}
                handleListingSelect={handleListingSelect}
                handleCategorySelect={handleCategorySelect}
              />
            ) : (
              <StyledButton onClick={() => expand()}>
                <MenuIcon />
              </StyledButton>
            )
          ) : null}
        </Flex>
      </Box>
      {width < 780 && expanded && (
        <HeaderSearch
          mobile={width < 780}
          categories={categoryList}
          locations={locationList}
          listings={listingsList}
          handleInputChange={handleInputChange}
          handleCategoryChange={handleCategoryChange}
          handleLocationChange={handleLocationChange}
          handleSearchSubmit={handleSearchSubmit}
          handleLocationSelect={handleLocationSelect}
          handleListingSelect={handleListingSelect}
          handleCategorySelect={handleCategorySelect}
        />
      )}
    </>
  );
};
export default Header;
