import styled from 'styled-components';
import { Link } from 'gatsby';

export const StyledWrapper = styled.div`
  height: 103px;
  line-height: 111px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.5s ease-out;

  @media (max-width: 1000px) {
    justify-content: center;
  }
  @media (max-width: 780px) {
    justify-content: space-between;
  }

  .Landing-Search-Form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .MuiAutocomplete-root {
    border: none !important;
    border-radius: 4px;
    font-family: Heebo !imporant;
    background: none !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #2ea5f2;
  }
  .Mui-focused {
    color: #2ea5f2;
  }
`;

export const HamburgerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 3%;

  svg {
    width: 40px;
    align-self: center;
  }
`;
export const StyledLink = styled.a`
  color: #2ea5f2;
  margin-right: 3%;
  font-size: 20px;
  text-decoration: none;
  @media (max-width: 1680px) {
    display: none;
  }
  :focus {
    outline: none;
  }
  :hover {
    color: #145f8f;
  }
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  display: inline;

  :focus {
    outline: none;
  }
`;
