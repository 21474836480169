import React from 'react';
import _ from 'lodash';
import AutoComplete from '../../../atoms/AutoComplete/AutoComplete';
import StyledIcon from '../../../atoms/SearchIcon/SearchIcon';
import { Flex, Box, Button } from 'rebass/styled-components';

const HeaderSearch = (props) => {

  const isMobile = props.mobile;
  return (
    <Flex
      className={isMobile ? 'mobile-header-search' : 'header-search'}
      bg="white"
      flexDirection={isMobile ? 'column' : 'row'}
      width={1}
      ml={isMobile ? 0 : [17]}
      py={isMobile ? 4 : null}
      sx={{
        position: isMobile ? 'absolute' : 'relative',
        boxShadow: isMobile ? '0 2px 5px 0 rgba(106, 126, 150, 0.14)' : null,
        left: 0,
      }}
      px={isMobile ? 4 : 0}
    >
      <Box width={isMobile ? '100%' : 265}>
        <AutoComplete
          width="100%"
          id="name"
          className="Landing-Search-Input1"
          items={props.listings}
          placeholder="Business Name"
          handleChange={props.handleInputChange}
          handleSelect={props.handleListingSelect}
        />
      </Box>
      <Box width={isMobile ? '100%' : 265} mt={isMobile ? 2 : 0}>
        <AutoComplete
          width="100%"
          id="category"
          className="Landing-Search-Categories"
          items={props.categories}
          placeholder="Category"
          handleChange={props.handleCategoryChange}
          handleSelect={props.handleCategorySelect}
        />
      </Box>
      <Box width={isMobile ? '100%' : 265} mt={isMobile ? 2 : 0}>
        <AutoComplete
          width="100%"
          id="location"
          className="Landing-Search-Input2"
          items={props.locations}
          placeholder="City, State or Zip"
          handleChange={props.handleLocationChange}
          handleSelect={props.handleLocationSelect}
        />
      </Box>
      {isMobile && (
        <Button variant="primary" onClick={props.handleSearchSubmit} maxWidth={185} mx="auto" mt={4}>
          Search
        </Button>
      )}
      {isMobile != true && (
        <Button ml={-12} variant="icon" sx={{ zIndex: '1' }} onClick={props.handleSearchSubmit}>
          <StyledIcon />
        </Button>
      )}
    </Flex>
  );
};
export default HeaderSearch;
