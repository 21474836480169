const listingQuery = {
  getPaginationAmount: (name, category, city, state, country, max, offset) => `query searchListings {
    searchListings(input: {
      max: ${max},
      offset: ${offset},
      ${name ? `name: "${name}",` : ''}
      ${category ? `categories: { name: "${category}" },` : ''}
      ${city ? `city: "${city}",` : ''}
      ${state ? `state: "${state}",` : ''}
      ${country ? `country: "${country}",` : ''}
    }) {
      id
  }`,
  searchListings: (name, category, city, state, country, max, offset) => `query searchListings {
    searchListings(input: {
      max: ${max},
      offset: ${offset},
      ${name ? `name: "${name}",` : ''}
      ${category ? `categories: { name: "${category}" },` : ''}
      ${city ? `city: "${city}",` : ''}
      ${state ? `state: "${state}",` : ''}
      ${country ? `country: "${country}",` : ''}
    }) {
      id
      name
      address
      city
      state
      zip
      slug
      specialOffer {
        title
      }
      country
      phones {
        type
        number
      }
      hideAddress
      images {
        url
        description
        type
      }
      pin {
        location {
          lat
          lon
        }
      }
      hideAddress
      showHours
    }
  }`,
  getListing: (listingSlug) => `query searchListings {
        searchListings(input: { slug: "${listingSlug}" }) {
          id
          name
          address
          hours{
            closed
            periods{
              from
              to
            }
            day
          }
          phones {
            type
            number
          }
          specialOffer {
            description
            title
          }
          hideAddress
          zip
          city
          state
          country
          categories {
            name
          }
          description
          socialProfiles {
            url
            description
            type
          }
          images {
            url
            description
            type
          }
          videos {
            url
            description
            type
          }
          keywords
          paymentOptions
          hideAddress
          emails {
            email
            description
          }
          services
          languages
          urls {
            url
            description
            type
          }
          content {
            type
            name
            title
            timeStart
            currency
            price
            images {
              url
              description
              type
            }
            image {
              url
              description
              type
            }
            description
          }
          showHours
        }
      }`,
  getAutocompleteResults: (searchTerm) => `query getAutocompleteResults {
    getAutocompleteResults(queryString: "${searchTerm.split(' ').join(' + ').concat('*')}") {
      ... on CategoryRecord {
        id
        name
        fullName
      }
    }
  }`,
  getLocationAutocompleteResults: (searchTerm) => `query getLocationAutocomplete {
    getLocationAutocomplete(input: "${searchTerm}") {
      name
    }
  }`,
  getListingAutocompleteResults: (searchTerm) => `query searchListings {
    searchListings(input: { name: "${searchTerm}"}) {
      name
    }
  }`,
};

export default listingQuery;
