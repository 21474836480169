import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Flex } from 'rebass/styled-components';
import { theme } from '../theme/theme';
import { GlobalStyle } from '../theme/globalStyles';
import Footer from '../components/atoms/Footer/Footer';
import Header from '../components/atoms/Header/Header';
import { Location } from '@reach/router';

const Layout = ({ children, includeSearchBar, ...props }) => (
  <Location>
    {({ navigate, location }) => (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header location={location} includeSearchBar={includeSearchBar} />
        <Flex as="main" className="layout-page-wrapper" flexDirection="column" alignItems="center" {...props}>
          {children}
        </Flex>
        <Footer />
      </ThemeProvider>
    )}
  </Location>
);

export default Layout;
