import AppSyncClient, { createAppSyncLink } from 'aws-appsync';
import gql from 'graphql-tag';
import fetch from 'node-fetch';
import { createHttpLink } from 'apollo-link-http';
import 'cross-fetch/polyfill';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
// since Gatsby uses the Webpack DefinePlugin to make the environment variables available at runtime, they cannot be destructured from process.env;
// import { createAuthLink } from 'aws-appsync-auth-link';
// import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloLink } from 'apollo-link';

class CustomAppSyncClient extends AppSyncClient {
  constructor(options) {
    super({ ...options, disableOffline: true });
  }

  query(options) {
    return super.query({
      ...options,
      fetchPolicy: 'no-cache',
      query: gql(options.query),
      addTypename: false,
    });
  }

  mutate(options) {
    return super.mutate({
      ...options,
      fetchPolicy: 'no-cache',
      mutation: gql(options.mutation),
      addTypename: false,
    });
  }

  subscribe(options) {
    return super.subscribe({
      ...options,
      fetchPolicy: 'no-cache',
      query: gql(options.query),
    });
  }
}
const url = process.env.REACT_APP_COMPANYQL_URL;
const region = process.env.REACT_APP_REGION;
const auth = {
  type: 'API_KEY',
  apiKey: process.env.REACT_APP_COMPANYQL_API_KEY,
};
// const link = ApolloLink.from([
//   createAuthLink({ url, region, auth }),
//   createSubscriptionHandshakeLink({ url, region, auth })
// ]);

// const companyQL = new ApolloClient({
//   link,
//   cache: new InMemoryCache()
// })
// const httpLink = createHttpLink({
//   uri: process.env.REACT_APP_COMPANYQL_URL,
//   fetch
// });
// const link = createAppSyncLink({
//   url,
//   region,
//   auth,
// });
// const link = ApolloLink.from([
//   httpLink,
//   appSyncLink
// ]);
const httpLink = createHttpLink({ uri: '/graphql', fetch: fetch });
const companyQL = new CustomAppSyncClient({
  url,
  region,
  auth,
  fetch,
});

export default companyQL;
